import axios from 'axios';

const url = process.env.VUE_APP_ROOT_API ;

export default {
  getInternalAccounts(params){ //console.log(params);
    return axios.post(url + '/customer-care-portal/audit/intermediate/list/daily/0/1000',params ).then(response =>response.data.payload)
      .catch((error) => { return error.response });
  },

  getExternalAccounts(params){ //console.log(params);
    return axios.post(url + '/customer-care-portal/audit/external/list/daily/0/1000',params).then(response =>response.data.payload)
    .catch((error) => { return error.response });
  },

  getUserTierList(){
    return axios.get(url + '/opay-api/user-tier/list').then(response =>response.data.payload)
    .catch((error) => { return error.response });
  },

  getUserTierById(id){
    return axios.get(url + '/opay-api/user-tier/get/'+id).then(response =>response.data.payload)
    .catch((error) => { return error.response });
  },

  getUserTierByCode(code){
    return axios.get(url + '/opay-api/user-tier/get-by-code/'+code).then(response =>response.data.payload)
    .catch((error) => { return error.response });
  },

  updateUserTier(id,params){
    return axios.post(url + '/opay-api/user-tier/update/'+id,params).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  uploadTierImg(id,formData){
    return axios.post(url + '/opay-api/user-tier/resource-upload/' + id, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  downloadTierImg(key){
    return axios.get(url + '/opay-api/user-tier/resource-download/'+key,{responseType: 'arraybuffer'}).then(response =>Buffer.from(response.data, 'binary').toString('base64'))
    .catch((error) => { return error.response });
  },

  downloadTierWalletImg(key){
    return axios.get(url + '/opay-api/user/wallet-image-download/'+key,{responseType: 'arraybuffer'}).then(response =>Buffer.from(response.data, 'binary').toString('base64'))
    .catch((error) => { return error.response });
  },

  getKYCSampleImage(){
    return axios.get(url + '/opay-api/kyc/get-all-sample-image-cards').then(response =>response.data.payload)
    .catch((error) => { return error.response });
  },

  createKYCImage(params){
    return axios.post(url + '/opay-api/kyc/create-sample-image-card',params).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  updateKYCImage(id,params){
    return axios.post(url + '/opay-api/kyc/update-sample-image-card/'+id,params).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  getKycImageById(id){
    return axios.get(url + '/opay-api/kyc/get-sample-image-card/'+id).then(response =>response.data.payload)
    .catch((error) => { return error.response });
  },

  downloadKycImage(key){
    return axios.get(url + '/opay-api/kyc/resource-download/'+key,{responseType: 'arraybuffer'}).then(response =>Buffer.from(response.data, 'binary').toString('base64'))
    .catch((error) => { return error.response });
  },

  uploadKycImage(id,formData){
    return axios.post(url + '/opay-api/kyc/upload-sample-image/' + id, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(response =>response.data)
    .catch((error) => { return error.response });
  },



  getNotifications(userId){ //console.log(params);
    return axios.get(url + '/opay-api/admin/notification/list/'+userId).then(response =>response.data.payload)
    .catch((error) => { return error.response });
  },

  sendNotifications(params, isall=false){ //console.log(params);
    var nexturl = (isall) ? '/opay-api/admin/notification/send-to-all' : '/opay-api/admin/notification/send';
    return axios.post(url + nexturl ,params, {
      headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*'
      }
    }).then(response =>response.data).catch((error) => { return error.response });
  },

  getAllUserIdNotifList(){
    return axios.get(url + '/opay-api/admin/notification/user/list').then(response =>response.data)
    .catch((error) => { return error.response });
  },

  getNotifCategoryList(){
    return axios.get(url + '/opay-api/admin/notification/category/list').then(response =>response.data.payload)
    .catch((error) => { return error.response });
  },

  getDisabledNotifCategoryList(){
    return axios.get(url + '/opay-api/admin/notification/category/disabled/list').then(response =>response.data)
    .catch((error) => { return error.response });
  },

  createNewNotifCategory(params){
    return axios.post(url + '/opay-api/admin/notification/category/save',params).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  getNotifCategoryByCode(code){ console.log(code);
    return axios.get(url + '/opay-api/admin/notification/category/' + code).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  updateNotifCategory(params){
    return axios.post(url + '/opay-api/admin/notification/category/update',params).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  getdNotifTemplateList(){
    return axios.get(url + '/opay-api/admin/notification/template/list').then(response =>response.data.payload)
    .catch((error) => { return error.response });
  },

  getNotifTemplateById(id){
    return axios.get(url + '/opay-api/admin/notification/template/' + id).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  saveNotifTemplate(params){
    return axios.post(url + '/opay-api/admin/notification/template/save',params).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  deleteNotifTemplate(id){
    return axios.get(url + '/opay-api/admin/notification/template/remove/' + id).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  getNotifReport(){
    return axios.get(url + '/opay-api/admin/notification/notification-reports-list').then(response =>response.data)
    .catch((error) => { return error.response });
  },

  getNotifReportDetail(fileName){
    return axios.get(url + '/opay-api/admin/notification/notification-report-download/'+fileName).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  uploadWalletTierImg(tier,formData){
    return axios.post(url + '/opay-api/user/default-wallet-image-upload/' + tier, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(response =>response.data)
        .catch((error) => { return error.response });
  },

  tierListImg(){
    return axios.get(url + '/opay-api/user/default-wallet-image-list').then(response =>response.data)
        .catch((error) => { return error.response });
  },

};
